<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name Arabic"
              label-for="name-ar"
            >
              <b-form-input
                id="name-ar"
                v-model="certificateData.name.ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name Arabic"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Name English"
            rules="required"
          >
            <b-form-group
              label="Name English"
              label-for="name-en"
            >
              <b-form-input
                id="name-en"
                v-model="certificateData.name.en"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name English"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addCertificate"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addCertificate"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            /> Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      required,
      errors: {},
      loader: false,
      optionsDepartments: [],
      optionsProcessType: [
        { value: 'audit', text: 'audit' },
        { value: 'inspection', text: 'inspection' },
        { value: 'null', text: 'study' },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const certificateData = ref({
      name: {
        ar: '',
        en: '',
      },
    })
    return {
      getValidationState,
      certificateData,
    }
  },
  mounted() {
    this.showCertificate()
  },
  methods: {
    getValidationState(validationContext) {
      return validationContext.errors[0] ? false : null
    },
    showCertificate() {
      if (this.$route.params.id) {
        axios.get(`certificate_group/${this.$route.params.id}`).then(res => {
          this.certificateData = decryptData(res.data.payload).data.certificate
        })
      }
    },
    addCertificate() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        formData.append('name.ar', this.certificateData.name.ar)
        formData.append('name.en', this.certificateData.name.en)
        formData.append('id', this.certificateData.id)

        axios.post(`certificate_group/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'main-certificate' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        formData.append('name.ar', this.certificateData.name.ar)
        formData.append('name.en', this.certificateData.name.en)
        formData.append('id', this.certificateData.id)

        axios.post('certificate_group', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'main-certificate' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
